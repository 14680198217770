import {
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Box,
  Container,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { spacing } from "@mui/system";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
const RiskyAlerts = ({ alertJson }) => {
  const [riskyAlertsData, setRiskyAlertsData] = useState(null);

  const getRiskyAlerts = (json) => {
    const groupedData = json.reduce((acc, obj) => {
      console.log(obj.eventJson);
      const { Title, Level } = obj.eventJson.sigma_results[0];
      const key = `${Title}-${Level}`;

      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {});

    const sortedData = Object.entries(groupedData)
      .sort(([, countA], [, countB]) => countB - countA)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    const totalCount = Object.values(sortedData).reduce(
      (sum, count) => sum + count,
      0
    );
    const dataWithPercentage = Object.entries(sortedData).map(
      ([key, value]) => {
        const [Title, Level] = key.split("-");
        return {
          title: Title,
          level: Level,
          count: value,
          percentage: ((value / totalCount) * 100).toFixed(2),
        };
      }
    );
    setRiskyAlertsData(dataWithPercentage);
  };

  useEffect(() => {
    getRiskyAlerts(alertJson);
  }, [alertJson]);
  return (
    <Card>
      <CardHeader title={"Risky Alerts"} />
      <CardContent>
        <div
          style={{
            height: 350,
          }}
        >
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {riskyAlertsData !== null && (
              <>
                {riskyAlertsData.map((value, index) => (
                  <>
                    <Box
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <ListItem sx={{ p: 5 }}>
                        <ListItemText>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                padding: 5,
                                width: "60px",
                                borderRadius: 5,
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",

                                justifyContent: "space-around",
                                color: "#fff",
                                backgroundColor:
                                  value.level === "medium"
                                    ? "rgb(209,108,13)"
                                    : value.level === "high"
                                    ? "rgb(210,66,8)"
                                    : "rgb(245,12,12)",
                              }}
                            >
                              {value.level.charAt(0).toUpperCase() +
                                value.level.slice(1)}
                            </div>
                            <Typography
                              variant="body1"
                              fontWeight="bold"
                              sx={{
                                marginLeft: 5,
                              }}
                            >
                              {value.title}
                            </Typography>
                          </Box>
                        </ListItemText>
                      </ListItem>
                      <Box
                        maxWidth={100}
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          spacing={2}
                        >
                          <Stack spacing={2} sx={{ flex: 1 }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={value.percentage}
                            />
                          </Stack>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              marginLeft: 2,
                            }}
                          >
                            {value.percentage}%
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    {index < riskyAlertsData.length - 1 && <Divider />}
                  </>
                ))}
              </>
            )}
          </List>
        </div>
      </CardContent>
    </Card>
  );
};

export default RiskyAlerts;
