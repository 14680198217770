import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
  Box,
  Stack,
} from "@mui/material";
import { NGROK } from "../../../../APIs";
import {
  getInheritedResourceStatusValue,
  getProfileResourceNameField,
} from "../../../../services/Helpers";
import { useQuery } from "react-query";
import MyHeaderTableCell from "../../Components/MyHeaderTableCell";

const ResourcesTable = ({
  selectedCategory,
  resourceType,
  os,
  profile,
  selectedTenantName,
  setResourcesAreLoading,
}) => {
  const [profileResourceList, setProfileResourceList] = useState([]);
  const [matchedResources, setMatchedResources] = useState([]);
  const [categoriesResources, setCategoriesResources] = useState([]);

  const resourceNameField = getProfileResourceNameField(resourceType);

  const getAllSelectedResources = async (resourceType, os) => {
    const newOS = os === "mac" ? "macos" : os;
    try {
      const response = await axios.get(
        `${NGROK}/api/computers/operation-system/${resourceType}?os=${newOS}&tenantName=${selectedTenantName}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchResources = async (os, resourceType) => {
    let result;

    switch (resourceType) {
      case "App":
        result = await getAllSelectedResources("get-all-apps", os);
        break;
      case "Folder":
        result = await getAllSelectedResources("get-all-folders", os);
        break;
      case "Group":
        result = await getAllSelectedResources("get-all-groups", os);
        break;
      case "Publisher":
        result = await getAllSelectedResources("get-all-publishers", os);
        break;
      case "URLcontrol":
        result = await getAllSelectedResources("get-all-url-control", os);
        break;
      default:
        console.log("default");
    }

    if (result && result.content) {
      result.content.sort((a, b) => a.id - b.id);
      return result.content;
    }

    return [];
  };

  // const getAllMatchedResources = (resources, patterns) => {
  //   return resources?.filter((resource) =>
  //     patterns?.some((pattern) => {
  //       const escapedPattern = pattern.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  //       const regex = new RegExp(escapedPattern);
  //       return regex.test(resource[resourceNameField]);
  //     })
  //   );
  // };

  const fetchUrlControlList = async (os, profileId) => {
    try {
      return await axios.get(
        `${NGROK}/api/${os}/profiles/${profileId}/url-control`
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getFolders = async (os, profileId) => {
    const response = await axios.get(
      `${NGROK}/api/${os}/profiles/${profileId}/folders`
    );
    if (response.data) {
      const foldersSortedById = response.data.content.sort(
        (a, b) => a.folderId - b.folderId
      );
      return foldersSortedById;
    }
  };

  const getAllApplications = async (selectedProfileId) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/profiles/get-all-apps?profileId=${selectedProfileId}`
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const getResourceTrustLevel = (resource) => {
    const configuredProfile = selectedCategory?.profiles.find(
      (categoryProfile) => categoryProfile.name === profile.name
    );
    if (configuredProfile)
      return resource.profileResource !== "INHERITED"
        ? resource.profileResource
        : `${resource.profileResource} ${getInheritedResourceStatusValue(
            resource
          )}`;
    else return resource.profileResource;
  };

  const getResources = async () => {
    try {
      const filteredProfileResources = profileResourceList?.filter(
        (profileResource) =>
          categoriesResources?.some(
            (categoryResource) =>
              profileResource[resourceNameField] === categoryResource.path ||
              profileResource[resourceNameField] === categoryResource.name
          )
      );

      // const matchedResources = getAllMatchedResources(
      //   filteredProfileResources,
      //   selectedCategory?.searchPatterns
      // );

      setMatchedResources(filteredProfileResources);
    } catch (error) {
      console.error(error);
    }
  };

  const removeDuplicatesApps = (apps) => {
    return apps
      ?.reduce((accumulator, current) => {
        if (!accumulator.find((app) => app.appPath === current.appPath)) {
          accumulator.push(current);
        }
        return accumulator;
      }, [])
      .sort((a, b) => a.id - b.id);
  };

  const urlQueryResponse = useQuery({
    queryKey: ["profileUrlControl", profile.os, profile.id],
    queryFn: () => fetchUrlControlList(profile.os, profile.id),
    enabled: !!profile && resourceType === "URLcontrol",
  });

  const profileUrlControlList = urlQueryResponse
    ? urlQueryResponse.data?.data?.content
    : [];

  const { data: profileApps, isLoading: appsAreLoading } = useQuery({
    queryKey: ["profileApps", profile.id],
    queryFn: () => getAllApplications(profile.id),
    enabled: !!profile.id && resourceType === "App",
  });

  const { data: profileFolders, isLoading: foldersAreLoading } = useQuery({
    queryKey: ["profileFolders", os, profile.id],
    queryFn: () => getFolders(os, profile.id),
    enabled: !!os && !!profile.id && resourceType === "Folder",
  });

  const { data: allResources, isLoading: loading } = useQuery({
    queryKey: ["allResources", os, resourceType],
    queryFn: () => fetchResources(os, resourceType),
    enabled: !!os && !!resourceType,
  });

  useEffect(() => {
    const filteredResources = allResources?.filter((resource) =>
      resource.categories?.some(
        (category) =>
          category.categoryName.toLowerCase() ===
          selectedCategory?.name.toLowerCase()
      )
    );

    setCategoriesResources(filteredResources);
  }, [allResources, selectedCategory]);

  useEffect(() => {
    switch (resourceType) {
      case "App":
        const unicApps = removeDuplicatesApps(profileApps);
        setProfileResourceList(unicApps);
        break;
      case "Folder":
        setProfileResourceList(profileFolders);
        break;
      case "URLcontrol":
        setProfileResourceList(profileUrlControlList);
        break;
      default:
        console.log("default");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceType, selectedCategory]);

  useEffect(() => {
    if (profileResourceList && categoriesResources) getResources();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    resourceType,
    selectedCategory,
    profileResourceList,
    categoriesResources,
    resourceNameField,
  ]);

  useEffect(() => {
    if (appsAreLoading || foldersAreLoading || loading)
      setResourcesAreLoading(true);
    else setResourcesAreLoading(false);
  }, [appsAreLoading, foldersAreLoading, loading, setResourcesAreLoading]);

  if (appsAreLoading || foldersAreLoading)
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );

  return (
    <Stack
      spacing={2}
      direction={"column"}
      sx={{
        width: "49%",
        height: "fit-content",
      }}
    >
      {/* <Typography variant="h6" color="initial" fontWeight={600} align="center">
        Number of elements: {matchedResources?.length || "0"}
      </Typography> */}
      <TableContainer component={Paper}>
        <Table
          aria-label="simple table"
          sx={{
            height: "fit-content",
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead sx={{ backgroundColor: "#233044" }}>
            <TableRow>
              <TableCell sx={{ color: "white", width: 60 }} align="center">
                <Typography fontSize={17} fontWeight={600} width={60}>
                  Sl No
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography fontSize={17} fontWeight={600}>
                  {resourceType}
                </Typography>
              </TableCell>
              <MyHeaderTableCell align={"center"} className={"userName"}>
                <Typography fontSize={17} fontWeight={600}>
                  Trust level
                </Typography>
              </MyHeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matchedResources?.length && selectedCategory
              ? matchedResources.map((resource, i) => (
                  <TableRow key={resource.id}>
                    <TableCell component="td" scope="row" align="center">
                      {i + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: 540,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography
                        variant="body1"
                        maxWidth={300}
                        sx={{ wordWrap: "break-word", marginRight: 10 }}
                      >
                        {resource[resourceNameField]}
                      </Typography>
                    </TableCell>
                    <TableCell component="td" scope="row" align="center">
                      {getResourceTrustLevel(resource)}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default ResourcesTable;
