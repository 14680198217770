import React, { useEffect, useState } from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Stack,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import useUserStore from "../../../services/userStore";
import SearchField from "./components/SearchField";
import useGeneralSearch from "../../../hooks/useGeneralSearch";
import CustomDatePicker from "./components/CustomDatePicker";
import useDateFilter from "../../../hooks/useDateFilter";
import { fetchTenantsData } from "../api";

const ServerAuditLogs = () => {
  const userEmail = useUserStore((state) => state.user.email);
  const lastSession = useUserStore((state) => state.lastSession);

  const [showCount, setShowCount] = useState(50);

  const [tenantsList, setTenantsList] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [serverAuditLogs, setServerAuditLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const { latestTenant } = lastSession;

    const forbiddenSearchFields = ["bucket", "tenantName"];
  const { searchTerm, filteredData, handleSearch } =
    useGeneralSearch(serverAuditLogs, null, forbiddenSearchFields);
  const {
    dateRange,
    handleChangeDateRange,
    filteredData: logs,
  } = useDateFilter(filteredData, "time");

  useEffect(() => {
    const controller = new AbortController();
    const fetchAuditLogs = async () => {
      try {
        const res = await axios.get(`${NGROK}/api/server-audit-logs`, {
          signal: controller.signal,
        });
        setServerAuditLogs(res.data);
        setLoading(false);
      } catch (error) {
        console.error(error, "error fetchAuditLogs ");
        setLoading(false);
      }
    };

    fetchAuditLogs();

    const interval = setInterval(() => {
      fetchAuditLogs();
    }, 5000);

    return () => {
      controller.abort();
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    fetchTenantsData(userEmail, setTenantsList);
  }, [userEmail]);

  useEffect(() => {
    if (!latestTenant && tenantsList?.length) setSelectedTenant(tenantsList[0]);
    else {
      if (tenantsList?.length) {
        const tenant = tenantsList.find(
          (tenant) => tenant.tenantName === latestTenant
        );
        if (tenant) setSelectedTenant(tenant);
      }
    }
  }, [tenantsList, latestTenant]);

  const logsRelatedToSelectedTenant = logs?.filter(
    (log) => log.tenantName === selectedTenant?.tenantName
  );

  if (loading) return <CircularProgress />;

  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction={"row"}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={selectedTenant ? selectedTenant.tenantName : null}
          options={tenantsList.map((tenant) => tenant.tenantName)}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Tenant List" />
          )}
          onChange={(e, value) => {
            if (!value) {
              setSelectedTenant(null);
            }
            const tenant = tenantsList.find(
              (tenant) => tenant.tenantName === value
            );
            if (tenant !== undefined) {
              setSelectedTenant(tenant);
            }
          }}
        />
        <SearchField searchTerm={searchTerm} handleSearch={handleSearch} />
      </Stack>
      <CustomDatePicker
        dateRange={dateRange}
        handleChangeDateRange={handleChangeDateRange}
      />

      {logsRelatedToSelectedTenant.length ? (
        <TableContainer
          component={Paper}
          sx={{
            overflowX: "auto",
          }}
        >
          <Table
            size="small"
            aria-label="a dense table"
            sx={{
              height: "fit-content",
              "& td, & th": {
                border: "1px solid #233044",
              },
            }}
          >
            <TableHead
              sx={{
                backgroundColor: "#233044",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Time
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Logged-in-User
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Computer User
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Computer Name
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Resource Type
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Resource Name
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logsRelatedToSelectedTenant.slice(0, showCount).map((log) => (
                <TableRow key={log.id}>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.time}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.loggedInUserEmail}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      minWidth: "fit-content",
                      maxWidth: "300px",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                    align="center"
                  >
                    {log.computerUserEmail}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      minWidth: "fit-content",
                      maxWidth: "200px",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                    align="center"
                  >
                    {log.computerName}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.resourceType}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      maxWidth: "300px",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                    align="center"
                  >
                    {log.resourceName}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.action}
                  </TableCell>
                </TableRow>
              ))}
              {logsRelatedToSelectedTenant?.length > showCount ? (
                <TableRow
                  sx={{
                    border: 1,
                  }}
                >
                  <TableCell colSpan={7} align="center">
                    <Button
                      variant={"text"}
                      onClick={() =>
                        setShowCount((prevCount) => prevCount + 50)
                      }
                    >
                      Load more...
                    </Button>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      ) : selectedTenant ? (
        <Typography fontSize={16} p={5}>
          There are no logs related to selected Tenant
        </Typography>
      ) : (
        <Typography fontSize={16} p={5}>
          Select Tenant
        </Typography>
      )}
    </Stack>
  );
};

export default ServerAuditLogs;
