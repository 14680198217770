import React, { useState } from "react";
import { Button, Box, Grid, Link, TextField, Alert } from "@mui/material";
import useAuth from "../../../../hooks/useAuth";
import useUserStore from "../../../../services/userStore";
import { myLocalStorage } from "../../../../components/StorageHelper";
import { setInitialSessionTime } from "../../../../services/Helpers";
import {
  decodeKeycloakToken,
  sendTokenToBackend,
} from "../../../../utils/tokenHelpers";
import { getClientId } from "../../../../services/Auth";

const SignInForm = ({ togleActiveForm, email, realmName }) => {
  const { signIn } = useAuth();

  const fetchUserData = useUserStore((state) => state.getUserData);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleChange = (e) => {
    setPassword(e.target.value);
    setIsSubmitted(false);
    setPasswordError("");
  };

  const submitLogin = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    const clientId = getClientId(realmName);

    try {
      const loginData = {
        username: email,
        password,
        realmName,
        clientId,
      };

      const { access_token, refresh_token, userInfo } = await signIn(
        loginData
      );

      myLocalStorage.setItem("access_token", access_token);
      myLocalStorage.setItem("refresh_token", refresh_token);
      myLocalStorage.setItem("email", email);

      const { exp } = decodeKeycloakToken(access_token);
      sendTokenToBackend(realmName, email, userInfo.role, access_token, exp);
      setInitialSessionTime(30);
      fetchUserData(email);
    } catch (error) {
      console.error(error);
      setPasswordError("Wrong password");
    }
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => submitLogin(e)}
      noValidate
      sx={{ mt: 1 }}
      width={"100%"}
    >
      <TextField
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="new-password"
        onChange={(e) => handleChange(e)}
      />
      {passwordError && isSubmitted ? (
        <Alert sx={{ marginTop: 1 }} severity="error">
          {passwordError}
        </Alert>
      ) : null}
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Sign In
      </Button>
      <Grid container justifyContent="space-between">
        <Grid item xs={4}>
          <Link onClick={togleActiveForm} href="#" variant="body2">
            Back to Email
          </Link>
        </Grid>
        <Grid item xs={8} sx={{ textAlign: "end" }}>
          <Link href="#" variant="body2">
            Forgot password?
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignInForm;
