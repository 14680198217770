import React, { useEffect } from "react";
import axios from "axios";
import { Autocomplete, TextField, Box, CircularProgress } from "@mui/material";
import { NGROK } from "../../../APIs";
import useUserStore from "../../../services/userStore";
import { myLocalStorage } from "../../../components/StorageHelper";
import { useQuery } from "react-query";

const TenantSelection = ({ selectedTenant, setSelectedTenant }) => {
  const userEmail = useUserStore((state) => state.user.email);

  const fetchTenants = async (userEmail) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/get-all-domains?email=${userEmail}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const { data: tenantsList, isLoading } = useQuery({
    queryKey: ["tenantsList", userEmail],
    queryFn: () => fetchTenants(userEmail),
    enabled: !!userEmail,
  });

  const handleChange = (value) => {
    if (!value) {
      setSelectedTenant(selectedTenant);
    }
    const tenant = tenantsList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenant(tenant);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  useEffect(() => {
    if (tenantsList && !selectedTenant) setSelectedTenant(tenantsList[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant, tenantsList]);

  if (isLoading)
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );
  return (
    <Box>
      {tenantsList && tenantsList.length && selectedTenant ? (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={selectedTenant.tenantName}
          options={tenantsList.map((tenant) => tenant.tenantName)}
          sx={{
            width: 300,
            maxHeight: 50,
            backgroundColor: "white",
          }}
          renderInput={(params) => (
            <TextField {...params} label="Tenant List" />
          )}
          onChange={(e, value) => {
            handleChange(value);
          }}
        />
      ) : null}
    </Box>
  );
};

export default TenantSelection;
