import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import styled, { withTheme } from "styled-components/macro";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { spacing } from "@mui/system";
import { MoreVertical } from "react-feather";
const Card = styled(MuiCard)(spacing);
const ChartWrapper = styled.div`
  height: 378px;
`;
const AlertsDounut = ({ alertJson }) => {
  const [chartData, setChartData] = useState(null);
  const [alertData, setAlertData] = useState(null);

  const getDonutChartOptions = (alertJson) => {
    const groupedData = alertJson.reduce((acc, obj) => {
      console.log(obj);
      obj.eventJson = JSON.parse(obj.eventJson);
      const level = obj.eventJson.sigma_results[0].Level;
      console.log(level);
      acc[level] = (acc[level] || 0) + 1;
      return acc;
    }, {});
    console.log(groupedData);
    const order = { critical: 1, high: 2, medium: 3 };

    const orderedData = Object.fromEntries(
      Object.entries(groupedData).sort(([a], [b]) => order[a] - order[b])
    );

    setAlertData(orderedData);

    const labels = Object.keys(orderedData);
    const series = Object.values(orderedData);

    const chartData = {
      series: series,
      options: {
        chart: {
          type: "donut",
          stacked: true,

          toolbar: {
            show: false,
          },
        },

        colors: ["rgb(245,12,12)", "rgb(210,66,8)", "rgb(209,108,13)"],
        labels: labels,
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value) {
              return value + " times";
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
      },
    };
    setChartData(chartData);
  };

  useEffect(() => {
    getDonutChartOptions(alertJson);
  }, []);
  return (
    <>
      {chartData !== null && (
        <>
          <Card>
            <CardHeader
              title="Alerts level"
              action={
                <IconButton aria-label="settings" size="large">
                  <MoreVertical />
                </IconButton>
              }
            />
            <CardContent>
              <ChartWrapper>
                <Chart
                  options={chartData.options}
                  series={chartData.series}
                  type="donut"
                  height={180}
                />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Level</TableCell>
                      <TableCell>Count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {alertData !== null && (
                      <>
                        {Object.entries(alertData).map(
                          ([key, value], index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {key.charAt(0).toUpperCase() + key.slice(1)}
                              </TableCell>
                              <TableCell>{value}</TableCell>
                            </TableRow>
                          )
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </ChartWrapper>
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};

export default AlertsDounut;
