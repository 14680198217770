import React from "react";
import axios from "axios";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import ProfileTableRow from "../../Components/ProfileTableRow";
import useUserStore from "../../../../services/userStore";
import { NGROK } from "../../../../APIs";
import { sortProfiles } from "../../../../services/Helpers";

const ProfilesTable = ({
  osValue,
  profilesList,
  setProfilesList,
  selectedTenant,
  pendingProfiles,
}) => {
  const setSelectedProfile = useUserStore((state) => state.setSelectedProfile);

  const profilesFilteredByOs = profilesList?.filter((profile) =>
    osValue.toUpperCase() !== "MACOS"
      ? profile.os.toUpperCase() === osValue.toUpperCase()
      : profile.os.toUpperCase() === "MAC"
  );

  const sortedProfiles = sortProfiles(profilesFilteredByOs);

  const updateProfileOperatingMode = async (event, profile) => {
    const newMode = event.target.value;
    const updatedprofile = { ...profile, mode: newMode };
    const updatedProfiles = profilesList.map((element, index) =>
      element.id === updatedprofile.id
        ? (profilesList[index] = updatedprofile)
        : element
    );
    setProfilesList(updatedProfiles);

    try {
      if (newMode === "INHERITED_FROM_TENANT") {
        await axios.put(`${NGROK}/api/profiles/${profile.id}/operation-mode`, {
          inheritedByTenantName: selectedTenant.tenantName,
          mode: "INHERITED_FROM_TENANT",
        });
      } else {
        await axios.put(`${NGROK}/api/profiles/${profile.id}/operation-mode`, {
          mode: newMode,
        });
      }
    } catch (error) {
      console.error("Error during request to server");
    }
  };

  return (
    <>
      {sortedProfiles?.length ? (
        <Table
          size="large"
          sx={{
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead
            sx={{
              backgroundColor: "#233044",
              color: "white",
              "& .userName": {
                textAlign: "center",
                color: "white",
                fontSize: "16px",
              },
            }}
          >
            <TableRow>
              <TableCell className="userName">Profile</TableCell>
              <TableCell className="userName">Mode</TableCell>
              <TableCell className="userName">Default time</TableCell>
              <TableCell className="userName">Resources</TableCell>
              <TableCell className="userName">Categories</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedProfiles.map((profile) => (
              <React.Fragment key={profile.id}>
                <ProfileTableRow
                  setProfilesList={setProfilesList}
                  profile={profile}
                  updateProfileOperatingMode={updateProfileOperatingMode}
                  selectedTenant={selectedTenant}
                  setSelectedProfile={setSelectedProfile}
                />
              </React.Fragment>
            ))}
            {pendingProfiles?.length
              ? pendingProfiles.map((profile) => (
                  <TableRow sx={{ border: 1 }} component={Paper}>
                    <TableCell
                      sx={{ width: "100px" }}
                      component="td"
                      scope="row"
                      title={profile.profileName}
                    >
                      <Typography component={"span"} fontSize={14}>
                        {profile.profileName}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ width: "100px" }}
                      component="td"
                      scope="row"
                      colSpan={4}
                      align="center"
                    >
                      <Typography component={"span"} fontSize={16}>
                        Profile is being created...
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      ) : (
        <Typography component={"span"} fontSize={22} fontWeight={600} p={2}>
          There are no profiles for selected OS.
        </Typography>
      )}
    </>
  );
};

export default ProfilesTable;
