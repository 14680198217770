import React, { useState, useEffect } from "react";
import {
  Stack,
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Tooltip,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import MatchedResourceTable from "./MatchedResourceTable";
import useUserStore from "../../../../services/userStore";
import {
  createCategoryPatternRegex,
  getResourceNameField,
} from "../../../../services/Helpers";
import { createCategory } from "../../api";
import useDebouncedValue from "../../../../hooks/useDebouncedValue";
import PatternsBox from "../../Components/PatternsBox";

const CreateCategoryDialog = ({
  allSelectedResources,
  resourceType,
  os,
  fetchCategories,
  selectedTenantName,
  removedAppDuplicates,
}) => {
  const email = useUserStore((state) => state.user.email);

  const [newCategory, setNewCategory] = useState("");
  const [categoryPriority, setCategoryPriority] = useState(10);
  const [matchedResources, setMatchedResources] = useState([]);
  const [patternInputText, setPatternInputText] = useState("");
  const [searchPatternList, setSearchPatternList] = useState([]);
  const [manualExclusions, setManualExclusions] = useState([]);
  const [isCreateCategoryDialogOpen, setIsCreateCategoryDialogOpen] =
    useState(false);
  const [activeChip, setActiveChip] = useState(null);
  const debouncedSearchTerm = useDebouncedValue(patternInputText, 1000);

  const resourceNameField = getResourceNameField(resourceType);

  const getAppManualExclusions = (removedAppDuplicates) => {
    return removedAppDuplicates
      ?.map((app) => ({
        id: app.id,
        name: app.path,
      }))
      .filter((exclusion) =>
        manualExclusions.some(
          (manualExclusion) => manualExclusion.name === exclusion.name
        )
      );
  };

  const checkMatches = (newPattern) => {
    if (newPattern === "*") {
      setMatchedResources(allSelectedResources);
      return;
    } else if (!newPattern && !activeChip) {
      setMatchedResources([]);
      return;
    } else if (newPattern) {
      try {
        const regex =
          os === "WINDOWS"
            ? createCategoryPatternRegex(newPattern, true)
            : createCategoryPatternRegex(newPattern);

        const filteredResources = allSelectedResources.filter((resource) =>
          resource[resourceNameField].match(regex)
        );

        setMatchedResources(filteredResources);
      } catch (error) {
        console.error("Invalid regular expression:", error.message);
      }
    }
  };

  const handleDeletePattern = (id) => {
    const newPatternListFiels = searchPatternList.filter(
      (field) => field.id !== id
    );
    setSearchPatternList(newPatternListFiels);
  };

  const addPatternToCategory = (text, id) => {
    const updatedPatternList = [...searchPatternList, { id, text }];
    setSearchPatternList(updatedPatternList);
    setPatternInputText("");
  };

  const closeAndCleanDialog = () => {
    setIsCreateCategoryDialogOpen(false);
    setMatchedResources([]);
    setSearchPatternList([]);
    setPatternInputText("");
    setActiveChip(null);
  };

  const handleSubmit = () => {
    const exclusionsDupplicates = getAppManualExclusions(removedAppDuplicates);
    const newManualExclusions =
      resourceType !== "App"
        ? manualExclusions
        : [...manualExclusions, ...exclusionsDupplicates];

    createCategory(
      newCategory,
      categoryPriority,
      searchPatternList,
      os,
      resourceType,
      email,
      newManualExclusions,
      selectedTenantName
    );

    setTimeout(() => {
      fetchCategories(os);
    }, 1000);
    closeAndCleanDialog();
  };

  const getAllMatchedResources = (newPatterns) => {
    return allSelectedResources.filter((resource) => {
      return newPatterns.some((pattern) => {
        const regex =
          os === "WINDOWS"
            ? createCategoryPatternRegex(pattern.text, true)
            : createCategoryPatternRegex(pattern.text);
        return resource[resourceNameField].match(regex);
      });
    });
  };

  const handlePriority = (event) => {
    const newValue = event.target.value;

    if (newValue === "" || (newValue >= 1 && newValue <= 100)) {
      setCategoryPriority(newValue);
    }
  };

  const handleAddPattern = () => {
    const id = uuidv4();
    addPatternToCategory(patternInputText, id);
    setActiveChip({ id, text: patternInputText });
  };

  const handleChip = (textfield) => {
    setPatternInputText("");
    checkMatches(textfield.text);
    setActiveChip(textfield);
  };

  const handleAllMatchesChip = () => {
    setPatternInputText("");
    setActiveChip({ id: 999, text: "ALL" });
    setMatchedResources(getAllMatchedResources(searchPatternList));
  };

  const isSubmitButtonEnabled = () => {
    if (
      newCategory &&
      searchPatternList.length &&
      !(newCategory.trim() === "") &&
      categoryPriority
    )
      return true;
    else return false;
  };

  const handlePatternInputChange = (e) => {
    setPatternInputText(e.target.value);
    setActiveChip(null);
  };

  const cancelDialog = () => {
    setManualExclusions([]);
    closeAndCleanDialog();
  };

  useEffect(() => {
    checkMatches(debouncedSearchTerm);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const patternProps = {
    handlePatternInputChange,
    handleAddPattern,
    handleAllMatchesChip,
    handleChip,
    handleDeletePattern,
    patternInputText,
    matchedResources,
    searchPatternList,
    activeChip,
  };

  return (
    <>
      <Button
        size="medium"
        key={uuidv4()}
        variant="contained"
        color="primary"
        onClick={() => {
          setIsCreateCategoryDialogOpen(true);
        }}
      >
        Create new category
      </Button>
      <Dialog
        open={isCreateCategoryDialogOpen}
        onClose={cancelDialog}
        fullWidth
      >
        <DialogContent sx={{ overflow: "hidden" }}>
          <Stack spacing={4}>
            <Box
              height={60}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <TextField
                required
                autoFocus
                id="name"
                label="Category Name"
                type="text"
                fullWidth
                variant="outlined"
                inputProps={{ maxLength: 250 }}
                onChange={(e) => setNewCategory(e.target.value)}
                sx={{ height: 45, width: "70%" }}
              />
              <Tooltip
                disableFocusListener
                title="If a resource evaluates to multiple categories, it will be assigned the category with highest priority."
                // enterDelay={1500}
                leaveDelay={200}
              >
                <TextField
                  required
                  autoFocus
                  id="name"
                  label="Priority"
                  type="text"
                  variant="outlined"
                  value={categoryPriority}
                  onChange={handlePriority}
                  sx={{
                    height: 45,
                    width: "25%",
                  }}
                />
              </Tooltip>
            </Box>

            <PatternsBox {...patternProps} />

            {matchedResources.length ? (
              <Box
                component={"fieldset"}
                borderRadius={1}
                overflow={"auto"}
                maxHeight={"230px"}
              >
                <legend>Matched Resources</legend>
                <MatchedResourceTable
                  activeChip={activeChip}
                  setManualExclusions={setManualExclusions}
                  manualExclusions={manualExclusions}
                  matchedResources={matchedResources}
                  resourceNameField={resourceNameField}
                />
              </Box>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeAndCleanDialog}>
            Cancel
          </Button>
          <Button
            disabled={!isSubmitButtonEnabled()}
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateCategoryDialog;
