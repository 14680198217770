import React, { useCallback, useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import AlertCharts from "./AlertCharts";
import AlertsTable from "./AlertsTable";
import AlertsDounut from "./AlertsDounut";
import AlertsAnalytics from "./AlertsAnalytics";
import RiskyAlerts from "./RiskyAlerts";
import RiskyMachines from "./RiskyMachines";
import RiskyUsers from "./RiskyUsers";
import axios from "axios";
import { NGROK } from "../../../APIs";

const Alerts = () => {
  const [alertJson, setAlertJson] = useState(null);

  const fetchAlertsValue = useCallback(async () => {
    try {
      const response = await axios.get(`${NGROK}/api/audit-logs`);
      console.log(response);
      const requiresAlertsValue = [];
      response.data
        .filter((dataVal) => dataVal.resourceType === "Alert")
        .map((value) => {
          console.log(value);
          let {
            computerUserEmail,
            computerName,
            eventJson,
            message,
            resourceName,
            time,
          } = value;
          requiresAlertsValue.push({
            computerUserEmail: computerUserEmail,
            computerName: computerName,
            eventJson: eventJson,
            message: message,
            resourceName: resourceName,
            time: time,
          });
        });
      setAlertJson(requiresAlertsValue);
    } catch (error) {
      console.log(error, "error fetchAlertsValue");
    }
  });

  useEffect(() => {
    fetchAlertsValue();
  }, []);
  return (
    <>
      {alertJson !== null && (
        <Grid container gap={2} spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <AlertCharts alertJson={alertJson} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3.9}>
            <AlertsDounut alertJson={alertJson} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <AlertsTable alertJson={alertJson} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container gap={1}>
              <Grid item xs={12} sm={12} md={3.9}>
                <RiskyAlerts alertJson={alertJson} />
              </Grid>
              <Grid item xs={12} sm={12} md={3.9}>
                <RiskyUsers alertJson={alertJson} />
              </Grid>
              <Grid item xs={12} sm={12} md={3.9}>
                <RiskyMachines alertJson={alertJson} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Alerts;
