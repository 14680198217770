import React, { useState, useEffect } from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";

import "../pages.scss";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ProfileApplicationsControl from "./ProfileApplicationsControl";
import ProfileDataAcces from "./ProfileDataAcces";
import ProfilePrivilegeManagement from "./ProfilePrivilegeManagement";
import useUserStore from "../../../services/userStore";
import { myLocalStorage } from "../../../components/StorageHelper";
import { useLocation } from "react-router-dom";
import ProfileUrlControl from "./ProfileUrlControl";

const ProfileResources = () => {
  const location = useLocation();
  const locationState = location?.state;

  const [folders, setFolders] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedResourceId, setSelectedResourcesId] = useState(
    myLocalStorage.getItem("selectedProfileResourceId") || 1
  );
  const [showCount, setShowCount] = useState(50);

  const [groupsSearchValues, setGroupsSearchValues] = useState("");
  const [foldersSearchValues, setFoldersSearchValues] = useState("");

  const userData = useUserStore((state) => state.user);
  const lastSession = useUserStore((state) => state.lastSession);
  const setLastSession = useUserStore((state) => state.setLastSession);

  const selectedProfile =
    locationState?.defaultProfile || myLocalStorage.getItem("selectedProfile");
  const tenant =
    locationState?.selectedTenant || myLocalStorage.getItem("latestTenant");

  const getFolders = async (profile) => {
    setFolders(undefined);

    const response = await axios.get(
      `${NGROK}/api/${profile.os}/profiles/${profile.id}/folders`
    );
    if (response.data) {
      const foldersSortedById = response.data.content.sort(
        (a, b) => a.folderId - b.folderId
      );
      setFolders(foldersSortedById);
    }
  };

  const getProfileGroups = async (profile) => {
    setGroups(undefined);

    const response = await axios.get(
      `${NGROK}/api/${profile.os}/profiles/${profile.id}/membership`
    );

    if (response.data) {
      const groupsSortedById = response.data.content.sort(
        (a, b) => a.groupId - b.groupId
      );
      setGroups(groupsSortedById);
    }
  };

  useEffect(() => {
    setShowCount(50);
    switch (selectedResourceId) {
      // case 1:
      //   console.log("here wwere publishers");
      //   break;
      case 2:
        setFoldersSearchValues("");
        getFolders(selectedProfile);
        break;
      case 3:
        setGroupsSearchValues("");
        getProfileGroups(selectedProfile);
        break;
      default:
        console.log("default");
    }
  }, [selectedProfile, selectedResourceId]);

  useEffect(() => {
    myLocalStorage.setItem("selectedProfile", selectedProfile);
  }, [selectedProfile]);

  useEffect(() => {
    myLocalStorage.setItem("selectedProfileResourceId", selectedResourceId);
  }, [selectedResourceId]);

  return (
    <section className="domainTab">
      <Resources
        selectedResourceId={selectedResourceId}
        setSelectedResourcesId={setSelectedResourcesId}
        lastSession={lastSession}
        setLastSession={setLastSession}
      />

      {selectedResourceId === 1 && (
        <ProfileApplicationsControl
          userData={userData}
          selectedProfile={selectedProfile}
          showCount={showCount}
          setShowCount={setShowCount}
          tenantName={tenant.tenantName}
        />
      )}
      {selectedResourceId === 2 && (
        <ProfileDataAcces
          userData={userData}
          setFolders={setFolders}
          folders={folders}
          getFolders={getFolders}
          selectedProfileId={selectedProfile.id}
          showCount={showCount}
          setShowCount={setShowCount}
          foldersSearchValues={foldersSearchValues}
          setFoldersSearchValues={setFoldersSearchValues}
          tenantName={tenant.tenantName}
        />
      )}
      {selectedResourceId === 3 && (
        <ProfilePrivilegeManagement
          selectedProfileId={selectedProfile.id}
          userData={userData}
          setGroups={setGroups}
          groups={groups}
          getProfileGroups={getProfileGroups}
          showCount={showCount}
          setShowCount={setShowCount}
          setGroupsSearchValues={setGroupsSearchValues}
          groupsSearchValues={groupsSearchValues}
          tenantName={tenant.tenantName}
        />
      )}
      {selectedResourceId === 4 && (
        <ProfileUrlControl
          selectedProfile={selectedProfile}
          showCount={showCount}
          setShowCount={setShowCount}
          tenantName={tenant.tenantName}
        />
      )}
    </section>
  );
};

const Resources = ({
  selectedResourceId,
  setSelectedResourcesId,
  lastSession,
  setLastSession,
}) => {
  const resources = [
    {
      id: 1,
      title: "Application Control",
    },
    {
      id: 2,
      title: "Data Access",
    },
    {
      id: 3,
      title: "Privilege Management",
    },
    {
      id: 4,
      title: "URL Control",
    },
  ];

  return (
    <section>
      <TableContainer style={{ height: "fit-content" }} component={Paper}>
        <Table
          sx={{
            width: "100%",
            border: "1px solid #233044",
          }}
          size="large"
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{ color: "white", backgroundColor: "#233044" }}
                className="userName"
              >
                Resources
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resources.map((resource) => (
              <TableRow
                className={
                  resource.id === selectedResourceId
                    ? "userRow activeUserRow"
                    : "userRow"
                }
                key={resource.id}
                sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                onClick={() => {
                  setSelectedResourcesId(resource.id);
                  setLastSession({
                    ...lastSession,
                    resourceId: resource.id,
                  });
                }}
              >
                <TableCell
                  component="td"
                  scope="row"
                  className="userRowName userName"
                  title={resource.title}
                >
                  {resource.title}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
};
export default ProfileResources;
