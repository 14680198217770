import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";

import { styled } from "@mui/material/styles";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
const RiskyMachines = ({ alertJson }) => {
  const [riskyMachinesData, setRiskyMachinesData] = useState(null);
  const getRiskyMachines = (json) => {
    const groupedData = json.reduce((acc, obj) => {
      const { computerName } = obj;
      acc[computerName] = (acc[computerName] || 0) + 1;
      return acc;
    }, {});

    const sortedData = Object.entries(groupedData)
      .sort(([, countA], [, countB]) => countB - countA)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    const totalCount = Object.values(sortedData).reduce(
      (sum, count) => sum + count,
      0
    );
    const dataWithPercentage = Object.entries(sortedData).map(
      ([key, value]) => ({
        user: key,
        count: value,
        percentage: ((value / totalCount) * 100).toFixed(2),
      })
    );
    setRiskyMachinesData(dataWithPercentage);
  };

  useEffect(() => {
    getRiskyMachines(alertJson);
  }, [alertJson]);
  return (
    <Card>
      <CardHeader title={"Risky Machines"} />
      <CardContent>
        <div
          style={{
            height: 350,
          }}
        >
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {riskyMachinesData !== null && (
              <>
                {riskyMachinesData.map((value, index) => (
                  <>
                    <Box
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <ListItem sx={{ p: 5 }}>
                        <ListItemIcon>
                          <ComputerOutlinedIcon />
                        </ListItemIcon>

                        <ListItemText>
                          <Typography variant="body1" fontWeight="bold">
                            {value.user}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <Box
                        maxWidth={100}
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          spacing={2}
                        >
                          <Stack spacing={2} sx={{ flex: 1 }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={value.percentage}
                            />
                          </Stack>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              marginLeft: 2,
                            }}
                          >
                            {value.percentage}%
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    {index < riskyMachinesData.length - 1 && <Divider />}
                  </>
                ))}
              </>
            )}
          </List>
        </div>
      </CardContent>
    </Card>
  );
};

export default RiskyMachines;
