import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";

import {
  Grid,
  Divider as MuiDivider,
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { green, red, yellow } from "@mui/material/colors";

import BarChart from "./BarChart";
import LineChart from "./LineChart";
import Stats from "./Stats";
import axios from "axios";
import { NGROK } from "../../../APIs";
import useUserStore from "../../../services/userStore";
import { myLocalStorage } from "../../../components/StorageHelper";
import GrafanaChart from "./GrafanaChart";
import GrafanaDashboardEmbed from "./GrafanaDashboardEmbed";
import ResoruceType from "./ResoruceType";
import TimeBasedSeries from "./TimeBasedSeries";
import TopComputer from "./TopComputer";
import TopResources from "./TopResources";
import RecentActivity from "./RecentActivity";
import RecentLogin from "./RecentLogin";

const Divider = styled(MuiDivider)(spacing);

function Default() {
  const userData = useUserStore((state) => state.user);
  const [selectedTenantName, setSelectedTenantName] = useState(null);

  const [identitiesCount, setIdentitiesCount] = useState(0);
  const [identitiesCountAtMonthStart, setIdentitiesCountAtMonthStart] =
    useState(0);
  const [resourcesCount, setResourcesCount] = useState(0);
  const [resourcesCountAtMonthStart, setResourcesCountAtMonthStart] =
    useState(0);
  const [sessionsCount, setSessionsCount] = useState(0);
  const [suspiciousAccountsCount, setSuspiciousAccountsCount] = useState(0);
  const [
    suspiciousAccountsCountAtMonthStart,
    setSuspiciousAccountsCountAtMonthStart,
  ] = useState(0);
  const [identitiesPercentegDifferences, setIdentitiesPercentegDifferences] =
    useState(0);
  const [resourcesPercentegDifferences, setResourcesPercentegDifferences] =
    useState(0);
  const [activeSessionsPercentegDifferences] = useState(0);
  const [
    suspiciousAccountsPercentegDifferences,
    setSuspiciousAccountsPercentegDifferences,
  ] = useState(0);
  const [auditData, setAuditData] = useState(0);

  const [tenantList, setTenantList] = useState([]);

  const calculatePercentageDifference = (currentCount, atMonthStartCount) => {
    const smallerNumber = Math.min(currentCount, atMonthStartCount);
    const biggerNumber = Math.max(currentCount, atMonthStartCount);

    if (currentCount === 0 && atMonthStartCount !== 0) return "-100";
    else if (currentCount !== 0 && atMonthStartCount === 0) return "100";
    else if (currentCount === 0 && atMonthStartCount === 0) return "0.00";
    else {
      const differenceInPercentage =
        ((biggerNumber - smallerNumber) / smallerNumber) * 100;

      return currentCount > atMonthStartCount ||
        currentCount === atMonthStartCount
        ? `${differenceInPercentage.toFixed(2)}`
        : `-${differenceInPercentage.toFixed(2)}`;
    }
  };

  const fetchStatisticsData = async (endpoint, setter) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/statistics${endpoint}?tenantName=${selectedTenantName}`
      );

      setter(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (tenantList?.length === 1)
      setSelectedTenantName(tenantList[0].tenantName);
  }, [tenantList]);

  useEffect(() => {
    const storageKey = `get-all-domains?email=${userData.email}-30min`;

    const storageDomains = myLocalStorage.getItem(storageKey);

    if (storageDomains && storageDomains.length) {
      setTenantList([]);
      setTimeout(() => {
        setTenantList(storageDomains);
      }, 500);
    } else {
      const fetchTenants = async () => {
        const response = await axios.get(
          `${NGROK}/api/get-all-domains?email=${userData.email}`
        );
        setTenantList(response.data);
        myLocalStorage.setItem(storageKey, response.data, 30);
      };
      fetchTenants();
    }
  }, [userData]);

  const fetchIdentetiesCount = useCallback(async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/${selectedTenantName}/computer-users/count`
      );
      setIdentitiesCount(response.data);
    } catch (error) {
      console.log(error, "error fetchIdentetiesCount");
    }
  }, [selectedTenantName]);

  const fetAuditData = useCallback(async () => {
    try {
      const response = await axios.get(`${NGROK}/api/audit-logs`);
      let { data } = response;
      setAuditData(data);
    } catch (e) {
      console.log("defauklt call error", e);
    }
  }, []);

  const fetchResourcesCount = useCallback(async () => {
    try {
      const response = await axios.all([
        axios.get(`${NGROK}/api/${selectedTenantName}/groups/count`),
        axios.get(`${NGROK}/api/${selectedTenantName}/apps/count`),
        axios.get(`${NGROK}/api/${selectedTenantName}/folders/count`),
      ]);
      const result = response.reduce((acc, current) => {
        return acc + current.data;
      }, 0);
      setResourcesCount(result);
    } catch (error) {
      console.log(error, "error fetchResourcesCount");
    }
  }, [selectedTenantName]);

  const fetchSessionsCount = useCallback(async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/sessions/${selectedTenantName}/count`
      );
      setSessionsCount(response.data);
    } catch (error) {
      console.log(error, "error fetchSessionsCount");
    }
  }, [selectedTenantName]);

  const fetchSuspiciousAccountsCount = useCallback(() => {
    let suspiciousAccounts;
    try {
      if (userData.role === "TENANT_ADMIN" || userData.role === "SYS_ADMIN") {
        axios
          .get(`${NGROK}/api/computer-users/tenant?name=${selectedTenantName}`)
          .then((res) => {
            if (res.data) {
              suspiciousAccounts = res.data.filter(
                (account) => account.suspiciousAccount
              );
            }
          });
      } else if (userData.role === "TENANT_USER") {
        axios
          .get(`${NGROK}/api/computer-users/tenant?name=${selectedTenantName}`)
          .then((res) => {
            if (res.data) {
              suspiciousAccounts = res.data.filter(
                (account) => account.suspiciousAccount
              );
            }
          });
      }
      if (suspiciousAccounts === undefined) suspiciousAccounts = [];
      setSuspiciousAccountsCount(suspiciousAccounts.length);
    } catch (error) {
      console.log(error, "error setSuspiciousAccountsCount");
    }
  }, [selectedTenantName, userData.role]);

  const handleTenantChange = (value) => {
    if (!value) {
      setIdentitiesCount(0);
      setResourcesCount(0);
      setSessionsCount(0);
      setSuspiciousAccountsCount(0);
    }
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
    }
  };

  useEffect(() => {
    const percentegDifferences = calculatePercentageDifference(
      identitiesCount,
      identitiesCountAtMonthStart
    );
    setIdentitiesPercentegDifferences(percentegDifferences);
  }, [identitiesCount, identitiesCountAtMonthStart]);

  useEffect(() => {
    const percentegDifferences = calculatePercentageDifference(
      resourcesCount,
      resourcesCountAtMonthStart
    );
    setResourcesPercentegDifferences(percentegDifferences);
  }, [resourcesCount, resourcesCountAtMonthStart]);

  useEffect(() => {
    const percentegDifferences = calculatePercentageDifference(
      suspiciousAccountsCount,
      suspiciousAccountsCountAtMonthStart
    );
    setSuspiciousAccountsPercentegDifferences(percentegDifferences);
  }, [suspiciousAccountsCount, suspiciousAccountsCountAtMonthStart]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchIdentetiesCount();
      fetchResourcesCount();
      fetchSessionsCount();
      fetchSuspiciousAccountsCount();
    }, 5000);
    return () => clearInterval(interval);
  }, [
    fetchIdentetiesCount,
    fetchResourcesCount,
    fetchSessionsCount,
    fetchSuspiciousAccountsCount,
  ]);

  useEffect(() => {
    fetchIdentetiesCount();
    fetchResourcesCount();
    fetchSessionsCount();
    fetchSuspiciousAccountsCount();
    fetchStatisticsData(
      "/computers-users/count",
      setIdentitiesCountAtMonthStart
    );
    fetchStatisticsData("/resources/count", setResourcesCountAtMonthStart);
    fetchStatisticsData(
      "/computers-users/suspicious/count",
      setSuspiciousAccountsCountAtMonthStart
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenantName]);

  useEffect(() => {
    fetAuditData();
  }, [fetAuditData]);

  return (
    <React.Fragment>
      {tenantList?.length > 1 ? (
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={selectedTenantName ? selectedTenantName : " "}
          options={tenantList.map((tenant) => tenant.tenantName)}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Tenant List" />
          )}
          onChange={(e, value) => {
            handleTenantChange(value);
          }}
        />
      ) : null}

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Identities"
            amount={
              identitiesCount !== undefined ? (
                identitiesCount
              ) : (
                <CircularProgress />
              )
            }
            chip="Today"
            percentagetext={`${identitiesPercentegDifferences}%`}
            percentagecolor={
              identitiesPercentegDifferences > 0
                ? green[500]
                : identitiesPercentegDifferences === 0
                ? yellow[700]
                : red[500]
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Resources"
            amount={
              resourcesCount !== undefined ? (
                resourcesCount
              ) : (
                <CircularProgress />
              )
            }
            chip="Today"
            percentagetext={`${resourcesPercentegDifferences}%`}
            percentagecolor={
              resourcesPercentegDifferences > 0
                ? green[500]
                : resourcesPercentegDifferences === 0
                ? yellow[700]
                : red[500]
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Active Sessions"
            amount={
              sessionsCount !== undefined ? sessionsCount : <CircularProgress />
            }
            chip="Today"
            percentagetext={`${activeSessionsPercentegDifferences}%`}
            percentagecolor={
              activeSessionsPercentegDifferences > 0
                ? green[500]
                : activeSessionsPercentegDifferences === 0
                ? yellow[700]
                : red[500]
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Suspicious accounts"
            amount={
              suspiciousAccountsCount !== undefined ? (
                suspiciousAccountsCount
              ) : (
                <CircularProgress />
              )
            }
            chip="Today"
            percentagetext={`${suspiciousAccountsPercentegDifferences}%`}
            percentagecolor={
              suspiciousAccountsPercentegDifferences > 0
                ? green[500]
                : suspiciousAccountsPercentegDifferences === 0
                ? yellow[700]
                : red[500]
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} lg={6} md={6}>
          <ResoruceType data={auditData} />
        </Grid>
        <Grid item xs={12} lg={6} md={6}>
          <TimeBasedSeries data={auditData} />
        </Grid>
        <Grid item xs={12} lg={12} md={12}>
          <Grid container spacing={5}>
            <Grid item xs={12} lg={6} md={6}>
              <Grid container gap={2}>
                <Grid item xs={12} lg={12} md={12}>
                  <TopComputer data={auditData} />
                </Grid>
                <Grid item xs={12} lg={12} md={12}>
                  <RecentLogin data={auditData} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6} md={6}>
              <RecentActivity data={auditData} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Default;
