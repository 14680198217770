import React from "react";

import RequestsTable from "./Components/RequestsTable";
import { Button, Stack } from "@mui/material";
import useGeneralSearch from "../../../hooks/useGeneralSearch";
import useNotificationStore from "../../../services/notificationStore";
import SearchField from "../logs/components/SearchField";
import CustomDatePicker from "../logs/components/CustomDatePicker";
import useDateFilter from "../../../hooks/useDateFilter";
import { handleGeneratePDF } from "./helpers";

const ResourceRequests = () => {
  const { adminNotifications, setAdminNotifications } = useNotificationStore();

  const allowedSearchFields = [
    "createdAt",
    "email",
    "computerName",
    "resourcePath",
    "requestStatus",
    "privilegeLevel"
  ];
  const {
    searchTerm,
    filteredData: notificationsFilteredBySearch,
    handleSearch,
  } = useGeneralSearch(adminNotifications, allowedSearchFields);

  const {
    dateRange,
    handleChangeDateRange,
    filteredData: filteredNotifications,
  } = useDateFilter(notificationsFilteredBySearch, "createdAt");

  return (
    <Stack spacing={2}>
      <Stack direction={"row"} spacing={2}>
        <CustomDatePicker
          dateRange={dateRange}
          handleChangeDateRange={handleChangeDateRange}
        />
        <SearchField searchTerm={searchTerm} handleSearch={handleSearch} />
        <Button variant="outlined" onClick={() => handleGeneratePDF(dateRange, filteredNotifications, searchTerm)}>
          Download Report
        </Button>
      </Stack>

      <RequestsTable
        adminNotifications={filteredNotifications}
        setAdminNotifications={setAdminNotifications}
      />
    </Stack>
  );
};

export default ResourceRequests;
