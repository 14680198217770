export const formatDateTime = (dateTimeStr) => {
  const dateTime = new Date(dateTimeStr);
  const now = new Date();
  const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  const diffMs = utcNow - dateTime;
  const diffMins = Math.round(diffMs / (1000 * 60));

  if (diffMins < 1) {
    return "just now";
  } else if (diffMins === 1) {
    return "1 min ago";
  } else if (diffMins < 60) {
    return `${diffMins} mins ago`;
  } else {
    const diffHours = Math.floor(diffMins / 60);
    if (diffHours < 24) {
      return `${diffHours} hrs ago`;
    } else {
      const diffDays = Math.floor(diffHours / 24);
      if (diffDays < 7) {
        return `${diffDays} days ago`;
      } else {
        const diffWeeks = Math.floor(diffDays / 7);
        if (diffWeeks < 4) {
          return `${diffWeeks} weeks ago`;
        } else {
          const diffMonths = Math.floor(diffDays / 30);
          if (diffMonths < 12) {
            return `${diffMonths} months ago`;
          } else {
            const diffYears = Math.floor(diffDays / 365);
            return `${diffYears} years ago`;
          }
        }
      }
    }
  }
};
