import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import GrafanaUI from "./GrafanaUI";

const GrafanaDashboardEmbed = () => {
  const [slugURL, setSlugURL] = useState(null);

  useEffect(() => {
    const fetchDashboard = async () => {
      await axios
        .get(`/grafana/api/dashboards/uid/ddgq6y1b86qkgd`)
        .then((response) => {
          const { data } = response;
          console.log(data);
          const { url } = data.meta;
          console.log(data);
          setSlugURL(data);
        })
        .catch((error) => {
          console.error("Error fetching dashboard JSON:", error);
        });
    };

    fetchDashboard();
  }, []);

  if (!slugURL) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <GrafanaUI url={slugURL} />
    </div>
  );
};

export default GrafanaDashboardEmbed;
