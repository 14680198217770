import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { NGROK } from "../../../APIs";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Stack,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import useUserStore from "../../../services/userStore";
import useGeneralSearch from "../../../hooks/useGeneralSearch";
import SearchField from "./components/SearchField";
import CustomDatePicker from "./components/CustomDatePicker";
import useDateFilter from "../../../hooks/useDateFilter";
import { fetchTenantsData } from "../api";
import { handleGeneratePDF } from "./components/helpers";
import TenantSelection from "../Components/TenantSelection";

const AuditLogs = () => {
  const userEmail = useUserStore((state) => state.user.email);
  const lastSession = useUserStore((state) => state.lastSession);

  const [showCount, setShowCount] = useState(50);

  const [tenantsList, setTenantsList] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);

  const [devicesList, setDevicesList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);

  const [auditLogs, setAuditLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const { latestTenant } = lastSession;

  const forbiddenSearchFields = ["bucket", "tenantName"];
  const { searchTerm, filteredData, handleSearch } = useGeneralSearch(
    auditLogs,
    null,
    forbiddenSearchFields
  );

  const {
    dateRange,
    handleChangeDateRange,
    filteredData: logs,
  } = useDateFilter(filteredData, "time");

  const fetchDevices = async (tenantName) => {
    try {
      const response = await axios.get(`${NGROK}/api/${tenantName}/computers`);
      setDevicesList(response.data);
    } catch (error) {
      console.log(error, "set devices lsit error");
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchAuditLogs = async () => {
      try {
        const res = await axios.get(`${NGROK}/api/audit-logs`, {
          signal: controller.signal,
        });
        setAuditLogs(res.data);
        setLoading(false);
      } catch (error) {
        console.error(error, "error fetchAuditLogs ");
        setLoading(false);
      }
    };

    fetchAuditLogs();

    const interval = setInterval(() => {
      fetchAuditLogs();
    }, 5000);

    return () => {
      controller.abort();
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    fetchTenantsData(userEmail, setTenantsList);
  }, [userEmail]);

  useEffect(() => {
    if (!latestTenant && tenantsList?.length) setSelectedTenant(tenantsList[0]);
    else {
      if (tenantsList?.length) {
        const tenant = tenantsList.find(
          (tenant) => tenant.tenantName === latestTenant
        );
        if (tenant) setSelectedTenant(tenant);
      }
    }
  }, [tenantsList, latestTenant]);

  useEffect(() => {
    if (selectedTenant) fetchDevices(selectedTenant.tenantName);
  }, [selectedTenant]);

  const filterLogs = (logs, tenantName, deviceName = "") => {
    return logs?.filter((log) =>
      !deviceName
        ? log.tenantName === tenantName
        : log.tenantName === tenantName && log.computerName === deviceName
    );
  };

  const deviceAuditLogs = selectedTenant
    ? filterLogs(logs, selectedTenant.tenantName, selectedDevice?.dNSHostName)
    : [];

  if (loading) return <CircularProgress />;

  return (
    <Stack spacing={4}>
      <Stack spacing={2} direction={"row"}>
        <TenantSelection
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
        />

        <Autocomplete
          disablePortal
          value={selectedDevice ? selectedDevice.dNSHostName : "All devices"}
          options={[
            "All devices",
            ...devicesList.map((device) => device.dNSHostName),
          ]}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Device List" />
          )}
          onChange={(e, value) => {
            if (!value) {
              setSelectedDevice(null);
            } else if (value === "All devices") {
              setSelectedDevice(null);
              return;
            }
            const device = devicesList.find(
              (device) => device.dNSHostName === value
            );
            if (device !== undefined) {
              setSelectedDevice(device);
            }
          }}
        />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <CustomDatePicker
          dateRange={dateRange}
          handleChangeDateRange={handleChangeDateRange}
        />
        <SearchField searchTerm={searchTerm} handleSearch={handleSearch} />
        <Button
          variant="outlined"
          onClick={() => handleGeneratePDF(dateRange, deviceAuditLogs, searchTerm)}
        >
          Download Report
        </Button>
      </Stack>

      {deviceAuditLogs.length ? (
        <TableContainer
          component={Paper}
          sx={{
            overflowX: "auto",
          }}
        >
          <Table
            size="small"
            aria-label="a dense table"
            sx={{
              "& td, & th": {
                border: "1px solid #233044",
              },
            }}
          >
            <TableHead
              sx={{
                backgroundColor: "#233044",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Time
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Computer User
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Computer Name
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Type
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Resource Name
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deviceAuditLogs.slice(0, showCount).map((log) => (
                <TableRow key={log.id}>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {moment(log.time).format("DD.MM.YYYY HH:mm:ss")}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.computerUserEmail}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.computerName}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.resourceType}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      maxWidth: "300px",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                    align="center"
                  >
                    {log.resourceName}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.action}
                  </TableCell>
                </TableRow>
              ))}
              {deviceAuditLogs?.length > showCount ? (
                <TableRow
                  sx={{
                    border: 1,
                  }}
                >
                  <TableCell colSpan={7} align="center">
                    <Button
                      variant={"text"}
                      onClick={() =>
                        setShowCount((prevCount) => prevCount + 50)
                      }
                    >
                      Load more...
                    </Button>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      ) : selectedTenant ? (
        <Typography fontSize={16} p={5}>
          There are no logs related to selected Tenant or Device
        </Typography>
      ) : (
        <Typography fontSize={16} p={5}>
          Select Tenant
        </Typography>
      )}
    </Stack>
  );
};

export default AuditLogs;
