import React, { useEffect, useState } from "react";
import axios from "axios";

import DetailsTableHead from "./components/DetailsTableHead";
import DetailsTableBody from "./components/DetailsTableBody";

import "../pages.scss";

import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

import useUserStore from "../../../services/userStore";
import { NGROK } from "../../../APIs";
import { useNavigate } from "react-router-dom";
import { myLocalStorage } from "../../../components/StorageHelper";

const TenantManagement = () => {
  const navigate = useNavigate();
  const userEmail = useUserStore((state) => state.user.email);

  const [tenantList, setTenantList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [tenantInfo] = useState(null);
  const [apiKeyLoading, setApiKeyLoading] = useState({
    tenant: null,
    loading: false,
  });

  const deleteTenant = (tenant_name) => {
    axios.delete(`${NGROK}/api/delete-tenant?name=${tenant_name}`);

    setTenantList((prev) => {
      return prev.filter((tenant) => tenant.tenantName !== tenant_name);
    });
  };

  const deleteApiKey = async (tenant) => {
    setApiKeyLoading({ tenant: tenant.tenantName, loading: true });
    await axios.delete(
      `${NGROK}/api/${tenant.tenantName}/tenant-admin/delete-api-key/${tenant.apiKey.key}?email=${tenant.email}`
    );

    setTenantList(
      tenantList.map((el) => {
        if (el.domainName === tenant.domainName) {
          return {
            ...el,
            apiKey: { key: "", expiredAt: "" },
          };
        } else {
          return el;
        }
      })
    );
    setApiKeyLoading({ tenant: tenant.tenantName, loading: false });
  };

  const generateApiKey = async (tenant) => {
    if (tenant) {
      setApiKeyLoading({ tenant: tenant.tenantName, loading: true });
      const res = await axios.get(
        `${NGROK}/api/${tenant.tenantName}/tenant-admin/get-api-key?email=${tenant.email}`
      );

      setTenantList(
        tenantList.map((el) => {
          if (el.domainName === tenant.domainName) {
            return {
              ...el,
              apiKey: { key: res.data.key, expiredAt: res.data.expiredAt },
            };
          } else {
            return el;
          }
        })
      );
      setApiKeyLoading({ tenant: tenant.tenantName, loading: false });
    }
  };

  const findTenant = (e) => {
    setSearchValue(e.target.value);
  };

  const handleCLoseTenantInfoBtn = () => {
    setSelectedTenantName(null);
  };
  const fetchTenants = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/get-all-domains?email=${userEmail}`
      );
      setTenantList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNavigateToUsers = (tenant) => {
    myLocalStorage.setItem("latestTenant", tenant);
    navigate("users", { state: { tenant } });
  };

  const handleNavigateEmailNotifications = (tenant) => {
    myLocalStorage.setItem("latestTenant", tenant);
    navigate("settings", { state: { tenant } });
  };

  useEffect(() => {
    fetchTenants();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail]);

  const filteredTenants =
    tenantList?.filter((tenant) =>
      searchValue
        ? tenant.tenantName.toLowerCase().includes(searchValue.toLowerCase())
        : true
    ) || [];

  return (
    <>
      <Stack
        spacing={2}
        direction={"row"}
        display={"flex"}
        alignItems={"center"}
      >
        <TextField
          sx={{ width: "30%", margin: "10px 0 10px 0" }}
          label="Search..."
          id="searchForTenant"
          onChange={findTenant}
        />
        <CreateTenantDialog fetchTenants={fetchTenants} />
      </Stack>
      <Grid container spacing={3}>
        <Grid item xs={selectedTenantName ? 7 : 12}>
          <TableContainer component={Paper}>
            <Table
              aria-label="simple table"
              sx={{
                "& td, & th": {
                  border: "1px solid #233044",
                },
              }}
            >
              <DetailsTableHead />

              <DetailsTableBody
                filteredTenants={filteredTenants}
                generateApiKey={generateApiKey}
                setTenantList={setTenantList}
                tenantList={tenantList}
                selectedTenantName={selectedTenantName}
                deleteApiKey={deleteApiKey}
                apiKeyLoading={apiKeyLoading}
                handleNavigateEmailNotifications={
                  handleNavigateEmailNotifications
                }
                handleNavigateToUsers={handleNavigateToUsers}
                deleteTenant={deleteTenant}
              />
            </Table>
          </TableContainer>
        </Grid>

        {selectedTenantName ? (
          <Grid item xs={5}>
            <TableContainer
              component={Paper}
              sx={{
                height: "fit-content",
                position: "sticky",
                top: 0,
                overflow: "hidden",
              }}
            >
              <Table aria-label="table">
                <TableHead
                  sx={{
                    backgroundColor: "#233044",
                  }}
                >
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ color: "white", fontSize: "16px" }}
                      colSpan={2}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography fontSize={16}>Tenant Info</Typography>
                        <Button
                          variant="outlined"
                          sx={{ color: "white" }}
                          onClick={handleCLoseTenantInfoBtn}
                        >
                          <CancelPresentationIcon sx={{ color: "white" }} />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(tenantInfo).map(
                    ([key, value]) =>
                      key !== "domainName" &&
                      key !== "tenantName" &&
                      key !== "bindPassword" &&
                      key !== "domainPasswordProtect" &&
                      key !== "domainPassword" && (
                        <TableRow>
                          <TableCell sx={{ fontSize: "16px" }}>
                            {key}:
                          </TableCell>
                          <TableCell sx={{ fontSize: "16px" }} align="center">
                            {value}
                          </TableCell>
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

const CreateTenantDialog = ({ fetchTenants }) => {
  const { email: userEmail } = useUserStore((state) => state.user);
  const setApiKey = useUserStore((state) => state.setApiKey);

  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [serverData, setServerData] = useState({
    tenantName: "",
    domainName: "",
    ldapServer: "",
    port: "",
    searchBase: "",
    server: "",
    bindDN: "",
    bindPassword: "",
    domainUser: "",
    domainPassword: "",
    policy: "",
    domainUserProtect: "",
    domainPasswordProtect: "",
    domainPolicyProtect: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setServerData((prev) => ({ ...prev, tenantName: "", domainName: "" }));
  };

  const handleCheckbox = () => {
    if (!isChecked) {
      setServerData({ ...serverData, domainName: serverData.tenantName });
    } else {
      setServerData({ ...serverData, domainName: serverData.domainName });
    }
  };

  const handleInputChange = (e) => {
    if (isChecked) {
      setServerData({
        ...serverData,
        [e.target.name]: e.target.value,
        domainName: e.target.value,
      });
    } else {
      setServerData({ ...serverData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${NGROK}/api/domain-info`, {
        ...serverData,
        email: userEmail,
      });

      setApiKey(response.data.key);
      fetchTenants();
    } catch {
      console.error("error duting sending server data");
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Button
        sx={{ height: "55px" }}
        size="large"
        variant={"outlined"}
        onClick={handleClickOpen}
      >
        Create new tenant
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Create new tenant</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter tenant/domain name.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Tenant Name"
            type="text"
            fullWidth
            variant="outlined"
            name="tenantName"
            value={serverData.tenantName}
            onChange={(e) => handleInputChange(e)}
          />
          <Stack direction={"row"} spacing={3}>
            <TextField
              disabled={isChecked}
              autoFocus
              margin="dense"
              id="name"
              label="Domain Name"
              type="text"
              fullWidth
              variant="outlined"
              name="domainName"
              value={serverData.domainName}
              onChange={(e) => handleInputChange(e)}
            />
            <FormControlLabel
              sx={{ minWidth: "190px" }}
              label={"Same as Tenant Name"}
              control={
                <Checkbox
                  value={isChecked}
                  onClick={(e) => {
                    handleCheckbox();
                    setIsChecked(!isChecked);
                  }}
                  aria-label="Checkbox demo"
                  defaultChecked
                />
              }
            ></FormControlLabel>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} disabled={!serverData.tenantName}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TenantManagement;
